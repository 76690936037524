import Swiper from 'swiper';
import { Navigation, Autoplay } from 'swiper/modules';
import { encodeHTML } from "./utils";

export const productsSlider = () => {
    new Swiper('.homepage .products__slider', {
        modules: [Navigation, Autoplay],
        autoplay: {
            delay: 3000,
            disableOnInteraction: false,
            pauseOnMouseEnter: false,
        },
        loop: true,
        speed: 600,
        slidesPerView: 'auto',
        spaceBetween: 20,
        navigation: {
            nextEl: '.products .products__slider-next',
            prevEl: '.products .products__slider-prev',
        },
    });

}

type Country = {
    id: string;
    products: string[]
}

export const interactiveMap = (): void => {
    const mapWrapper = document.querySelector('.homepage .home-map__map') as SVGSVGElement;

    if (!mapWrapper) return;

    const map = mapWrapper.querySelector('#world-map') as SVGSVGElement;

    // Parse countries data with proper error handling
    let countriesData: Country[] = [];
    try {
        countriesData = JSON.parse(map.dataset.countries || '[]');
    } catch (error) {
        console.error('Failed to parse countries data:', error);
    }

    const countries: Record<string, Country> = {};

    countriesData.forEach(country => {
        const path = map.querySelector(`#${CSS.escape(country.id)}`);
        path?.classList.add('highlight');
        countries[country.id] = country;
    });

    // Prep tooltip
    const tooltip = document.createElement('div');
    tooltip.className = 'map-tooltip';
    const tooltipTitle = document.createElement('h3');
    const tooltipList = document.createElement('ul');

    tooltip.append(tooltipTitle, tooltipList);
    tooltip.style.position = 'absolute';
    tooltip.style.pointerEvents = 'none'; // Prevent tooltip from interfering with mouse events

    // Variables to track current state
    let currentCountryId: string | null = null;
    let isTooltipVisible = false;

    // Function to update tooltip content
    const updateTooltipContent = (countryId: string): void => {
        const country = countries[countryId];
        const path = map.querySelector(`#${CSS.escape(countryId)}`) as SVGElement;

        tooltipTitle.textContent = path?.dataset.name || '';

        // Safely generate list content with XSS protection
        tooltipList.innerHTML = country.products
            .map(p => `<li>${encodeHTML(p)}</li>`)
            .join('');
    };

    // Function to update tooltip position
    const updateTooltipPosition = (e: MouseEvent): void => {
        const mapBBox = map.getBoundingClientRect();

        // Calculate tooltip position
        let posTop = e.clientY - mapBBox.top - tooltip.clientHeight - 5;
        let posLeft = e.clientX - mapBBox.left - tooltip.clientWidth;

        // Fix positioning logic
        if (e.clientX - mapBBox.left - tooltip.clientWidth + 20 <= 0) {
            posLeft = e.clientX - mapBBox.left;
        }

        if (e.clientY - mapBBox.top - tooltip.clientHeight + 50 <= 0) {
            posTop = e.clientY - mapBBox.top + 5;
        }

        // Use transform for smoother performance
        tooltip.style.transform = `translate(${posLeft}px, ${posTop}px)`;
    };

    // Add event listeners
    map.addEventListener('mouseenter', () => {
        if (!isTooltipVisible) {
            mapWrapper.appendChild(tooltip);
            isTooltipVisible = true;
            tooltip.style.opacity = '0'; // Start hidden until over a valid country
        }
    });

    map.addEventListener('mouseleave', () => {
        if (isTooltipVisible) {
            mapWrapper.removeChild(tooltip);
            isTooltipVisible = false;
            currentCountryId = null;
        }
    });

    map.addEventListener('mousemove', (e: MouseEvent) => {
        const target = e.target as SVGElement;
        const countryId = target.getAttribute('id');

        // Early return if not valid target or country
        if (target.tagName !== 'path' || !countryId || !countries[countryId]) {
            tooltip.style.opacity = '0';
            currentCountryId = null;
            return;
        }

        // Only update content if we moved to a different country
        if (currentCountryId !== countryId) {
            currentCountryId = countryId;
            updateTooltipContent(countryId);
            tooltip.style.opacity = '1';
        }

        // Always update position for smooth tracking
        updateTooltipPosition(e);
    });
};
