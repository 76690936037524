export function throttle<T extends (...args: any[]) => any>(
    func: T,
    wait: number
): (...args: Parameters<T>) => ReturnType<T> | undefined {
    let timeout: number | null = null;
    let previous = 0;

    return function (this: any, ...args: Parameters<T>): ReturnType<T> | undefined {
        const now = Date.now();
        const remaining = wait - (now - previous);

        if (remaining <= 0 || remaining > wait) {
            if (timeout) {
                clearTimeout(timeout);
                timeout = null;
            }
            previous = now;
            return func.apply(this, args);
        } else if (!timeout) {
            timeout = window.setTimeout(() => {
                previous = Date.now();
                timeout = null;
                func.apply(this, args);
            }, remaining);
        }
        return undefined;
    };
}


export const encodeHTML = (str: string): string => {
    return String(str)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;')
        .replace(/'/g, '&#39;');
};